<template>
  <div id="view-credit-history-view">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'CreditBalance'">
      <template v-slot:right-end>
        <div class="vx-row">
          <vs-button class="mt-3 py-4 px-3" color="primary"
                     @click="performDownloadHistory(true)"
                     v-if="walletHistory && walletHistory.length > 0">
            {{ $t('Download') }}

          </vs-button>
          <vs-button class="mt-3 py-4 px-3" color="primary"
                     @click="performDownloadHistory(true)"
                     v-if="walletHistory && walletHistory.length > 0">
            {{ $t('DownloadExcel') }}

          </vs-button>
          <div class="p-3"></div>
          <vs-button to="/customer/credit/add"
                     class="mt-3 py-4 px-3"
                     type="filled" icon-pack="feather"
                     icon="icon-plus">{{ $t('AddTopUp') }}
          </vs-button>
        </div>
      </template>
    </breadcrumb-base>

    <div class="">

      <div class="vx-row w-full">

        <div class="w-full md:w-1/5 vx-col">
          <vx-card class="mr-2">
            <div class="vs-col ">

              <dynamic-customers-drop-down
                :show-clear="true"
                :placeholder="$t('SearchCustomer')"
                :selected-item="customer"
                @on-item-selected="onCustomerFilterChanged">
              </dynamic-customers-drop-down>
              <div class="py-2"></div>
            </div>
            <div class="py-2"></div>
            <div class="vs-col w-full">
              <label style="font-size: 12px; font-weight: bold">
                {{ $t('TransactionMode') }}
                <span
                  @click="selectedPaymentMode = null;filterChanged()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <vs-select autocomplete v-model="selectedPaymentMode"
                         class="select-large sm:pb-6  w-full"
                         @input="filterChanged">
                <vs-select-item :key="index" :value="item" :text="getCreditModeText(item) | title | uppercase"
                                v-for="(item,index) in paymentData"/>
              </vs-select>
            </div>
            <div class="py-2"></div>

            <div class="vx-col w-full" v-if="showMangerDropDown">

              <label style="font-size: 12px; font-weight: bold">{{ $t('Manager') }}
                <span
                  @click="selectedManager = null;filterChanged()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <vs-select
                autocomplete
                class="pr-2 w-full pb-3"
                v-model="selectedManager"
                @input="filterChanged(-1)"
                :placeholder="$t('Manager')"
              >
                <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
                <vs-select-item :key="index" :value="customer" :text="customer.user_name"
                                v-for="(customer,index) in usersRaw.manager_accounts"/>
              </vs-select>
            </div>

            <div class="py-2"></div>

            <div class="vx-col w-full" v-if="showRefererDropDown">

              <label style="font-size: 12px; font-weight: bold">{{ $t('Staff') }}
                <span
                  @click="selectedReferer = null;filterChanged()"
                  class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
              </label>
              <vs-select
                autocomplete
                class="pr-2 w-full pb-3"
                v-model="selectedReferer"
                @input="filterChanged"
                :placeholder="$t('Staff')"
              >
                <vs-select-item key="all_referer" :value="null" :text="$t('All')"/>
                <vs-select-item :key="index" :value="customer.user_id" :text="customer.user_name"
                                v-for="(customer,index) in staffAccounts"/>
              </vs-select>
            </div>

            <div class="py-2"></div>

            <label style="font-size: 12px; font-weight: bold">{{ $t('FromDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('FromDate')"
                        :disabled-dates="{from: new Date()}"
                        v-model="startDate" v-on:select="filterChanged"></datepicker>
            <div class="py-2"></div>
            <label style="font-size: 12px; font-weight: bold">{{ $t('ToDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('ToDate')"
                        v-model="endDate" :disabled-dates="disabledDates"></datepicker>
            <div class="py-2"></div>


            <vs-button class="mt-3 w-full py-4 px-3" color="primary"
                       @click="filterChanged">{{ $t('Filter').toUpperCase() }}
            </vs-button>
            <div class="py-2"></div>

            <vs-button
              class="mt-3 w-full py-4 px-3"
              @click="clearAllFilters">{{ $t('ClearAll') }}
            </vs-button>
            <div class="py-2"></div>

          </vx-card>
        </div>
        <div class="w-full md:w-4/5 vx-col">
          <!--    Error List State-->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="walletHistory.length == 0">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
              </div>
            </div>
          </transition>
          <vx-card v-if="walletHistory.length > 0">
            <vs-table-modified stripe v-show="walletHistory.length > 0 && !errorFetching" ref="table"
                               class="custom-history-table"
                               :data="walletHistory">


              <template slot="thead">
                <!--                Staff , customer name , customer ID , Date time , value , transaction type , transaction mode , username , remark.-->
                <!--                date , customer , value , transaction mode , user , remark  — filter only transaction-->
                <vs-th>{{ $t('Date') }}</vs-th>
                <vs-th>{{ $t('CustomerID') }}</vs-th>
                <vs-th>{{ $t('Customer') }}</vs-th>
                <vs-th>{{ $t('Value') }}</vs-th>
                <vs-th>{{ $t('TransactionMode') }}</vs-th>
                <vs-th>{{ $t('User') }}</vs-th>
                <vs-th>{{ $t('Remarks') }}</vs-th>

              </template>

              <template slot-scope="{data}">
                <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p class=" truncate" style="max-width: 100px;white-space: normal !important;padding: 0 8px;">
                      {{ tr.updated | dateFormat }}</p>
                  </vs-td>
                  <vs-td>
                    <div class="vs-col">
                      <p class=" truncate" >
                        {{ tr.to_organisation_id }}
                      </p>
                      <p class="product-name font-medium truncate">{{ tr.to_organisation_name | capitalize }}</p>
                    </div>
                  </vs-td>
                  <vs-td>
                    <vx-tooltip>
                      <template slot="tip">
                        <div class="vs-col">
                          <p><strong>Mobile: {{ tr.mobile }}</strong></p>
                          <p v-if="tr.mobile_1"><strong>Mobile1: {{ tr.mobile_1 }}</strong></p>
                          <p v-if="tr.mobile_2"><strong>Mobile2: {{ tr.mobile_2 }}</strong></p>
                        </div>
                      </template>

                      <div class="vs-col">
                        <p class="truncate text-center" style="max-width: 200px;">{{ tr.to_organisation_first_name }}
                          {{ tr.to_organisation_last_name }}</p>
                        <p style="font-size: 11px; ">
                          {{ tr.address }}<span v-if="tr.house_no"> {{ tr.house_no }}</span>
                        </p>

                        <p style="font-size: 11px;">
                          {{ tr.postal }} {{ tr.city }}
                        </p>
                      </div>
                    </vx-tooltip>
                  </vs-td>
                  <vs-td>
                    <p class=" truncate" style="font-weight: bold;color:rgb(var(--vs-primary));">
                      {{ tr.value | germanNumberFormat }}</p>
                  </vs-td>
                  <vs-td>
                    <p class=" truncate">{{ tr.transaction_mode | title | uppercase }}</p>
                  </vs-td>
                  <vs-td>
                    <p class=" truncate">{{ tr.staff }}</p>
                  </vs-td>
                  <vs-td>
                    <p class=" truncate" style="max-width: 180px;white-space: normal !important;padding: 0 8px;">
                      {{ tr.remarks }}</p>
                  </vs-td>

                </vs-tr>
                </tbody>
                <thead class="vs-table--thead">
                <vs-tr v-if="selectedPaymentMode">
                  <vs-th>
                    <p>
                      {{ $t('Total') }} {{ selectedPaymentMode | title | uppercase }}
                    </p></vs-th>
                  <vs-th></vs-th>
                  <vs-th></vs-th>
                  <vs-th>
                    <p class=" truncate" style="font-weight: bold;">
                      {{ walletHistoryRaw.total_value | germanNumberFormat }}</p></vs-th>
                  <vs-th></vs-th>
                  <vs-th></vs-th>
                  <vs-th></vs-th>
                </vs-tr>
                </thead>
              </template>
              <template slot="footer-left">
                <div class="flex">
                  <vs-new-pagination :total="vTotalPages" v-model="currentPage" v-on:change="listPageChanged">
                  </vs-new-pagination>
                </div>
              </template>
            </vs-table-modified>
          </vx-card>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown'
import VxModal from "@/layouts/components/custom/VxModal";

import Datepicker from 'vuejs-datepicker'
import moment from 'moment'

export default {
  name: 'TopUpHistory',
  data() {
    return {
      customer: {},
      currentPage: 1,
      isMounted: false,
      errorFetching: false,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Customers', i18n: 'Customers', url: '/customers/view'},
        {title: 'TopUp Report', i18n: 'TopUpReport', active: true},
      ],
      startDate: null,
      endDate: null,
      selectedPaymentMode: null,
      selectedManager: null,
      selectedReferer: null,
    }
  },
  components: {
    VxModal,
    Datepicker,
    DynamicCustomersDropDown,
  },
  computed: {

    disabledDates() {
      if (!this.startDate) {
        return {
          from: new Date(),
        }
      }
      return {
        to: this.startDate,
        from: new Date(),
      }
    },
    paymentData() {
      return this.activeUserInfo.credit_transaction_type_modes
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    textColor() {
      if (this.customer.flag == 0) {
        return 'text-danger'
      }
      if (this.customer.flag == 1) {
        return 'text-success'
      }
      if (this.customer.flag == 2) {
        return 'text-warning'
      }
      return this.customer.flag ? 'text-primary' : 'text-grey'
    },
    color() {
      if (this.customer.flag == 0) {
        return 'danger'
      }
      if (this.customer.flag == 1) {
        return 'success'
      }
      if (this.customer.flag == 2) {
        return 'warning'
      }
      return 'primary'
    },
    vPage() {
      return this.$store.state.customer.vPage
    },
    vTotalCount() {
      return this.$store.state.customer.vTotalCount
    },
    vTotalPages() {
      return this.$store.state.customer.vTotalPages
    },
    walletHistory() {
      return this.$store.state.customer.walletHistory
    },
    transactionsTypeData() {
      if (this.walletHistoryRaw) {
        return this.walletHistoryRaw.transaction_type
      }
      return []
    },
    walletHistoryRaw() {
      return this.$store.state.customer.walletHistoryRaw
    },

    usersRaw() {
      return this.$store.state.user.usersRaw || {}
    },
    users() {
      return this.$store.state.user.users
    },
    staffAccounts() {
      if (this.selectedManager != null) {
        return this.selectedManager.staff_accounts
      } else {
        return this.usersRaw.staff_accounts;
      }
    },
    showMangerDropDown() {
      return this.activeUserInfo.internal_role === 0;
    },
    showRefererDropDown() {
      return this.activeUserInfo.internal_role === 0 || this.activeUserInfo.staff_role === 1;
    },
  },
  methods: {
    getCreditModeText(mode) {
      if(mode == 'credit_note') return 'Gutschrift'
      if(mode == 'credit') return 'Kredit'
      return mode
    },
    listPageChanged(item) {
      this.fetchWalletHistory(this.currentPage - 1)
    },
    clearAllFilters() {
      this.startDate = null
      this.endDate = null
      this.selectedPaymentMode = null
      this.selectedManager = null
      this.selectedReferer = null
      this.customer = {}

      this.fetchWalletHistory('0')
    },
    onCustomerFilterChanged(c) {
      this.customer = c
      this.filterChanged()
    },
    filterChanged(resetRefer) {
      if(resetRefer === -1) {
        this.selectedReferer = null
      }
      this.fetchWalletHistory('0')
    },
    onCustomerChanged(cust) {
      this.customer = cust
      this.filterChanged()

      this.fetchWalletHistory()
    },
    getTransactionType(value) {
      if (value === 0) return 'Credit'
      if (value === 1) return 'Cash'
      return ''
    },
    resetData() {
      this.selectedPaymentMode = null
      this.amount = ''
      this.remark = ''
      this.fetchWalletHistory()
      this.fetchCustomer()
    },
    fetchCustomer() {
      this.$store.dispatch('customer/fetchCustomerDetails', this.customer.customer_id)
        .then((data) => {
          this.customer = data
        })
        .catch((error) => {
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    getFilters() {
      let filter = {}
      if (this.startDate) {
        filter.start_date = moment(this.startDate).format('yyyy-MM-DD')
      }
      if (this.endDate) {
        filter.end_date = moment(this.endDate).format('yyyy-MM-DD')
      }
      if (Object.keys(this.customer).length > 0) {
        filter.customer_id = this.customer.customer_id
      }
      if (this.selectedPaymentMode != null) {
        filter.transaction_mode = this.selectedPaymentMode
      }
      if (this.selectedManager != null) {
        filter.refer_id = this.selectedManager.user_id
      }
      if (this.selectedReferer != null) {
        filter.refer_id = this.selectedReferer
      }
      return filter
    },

    performDownloadHistory(isExcel = false) {
      this.downloadWalletHistory(0, isExcel)
    },
    downloadWalletHistory(page = '0', isExcel = false) {
      this.$vs.loading()
      const filter = this.getFilters();
      filter.download = 1
      if (isExcel) {
        filter.download_type = 'excel'
      }
      this.$store.dispatch('customer/fetchDownloadCreditWalletHistory', {payload: filter})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
            if(isExcel) {
              const hiddenElement = document.createElement('a')
              hiddenElement.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
              hiddenElement.target = '_blank'
              hiddenElement.download = 'top_up_report.xlsx';
              hiddenElement.click()
            } else {
              const hiddenElement = document.createElement('a')
              hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data).replaceAll('#', '%23')}`
              hiddenElement.target = '_blank'
              hiddenElement.download = 'top_up_report.csv'
              hiddenElement.click()
            }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchWalletHistory(page = '0') {
      this.$vs.loading()
      this.$store.dispatch('customer/fetchCreditWalletHistory', {payload: this.getFilters(), page: page})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    loadReferer() {
      this.$vs.loading()
      this.$store.dispatch('user/fetchUsers', {})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    this.startDate = new Date();
    this.endDate = new Date();
    this.$store.commit('customer/RESET')
    this.fetchWalletHistory()
    this.loadReferer()
  },
  mounted() {
    this.isMounted = true
  },
}
</script>

<style lang="scss">
.amount-forecasted {
  color: #7cbb00;
  font-weight: bold;
}

.vx-card__title > h4 {
  font-size: 21.14px !important;
}

#view-credit-history-view {

  .custom-history-table {
    td {
      color: #515151 !important;
      font-weight: 500;
    }
  }

  .vs-input--label {
    font-weight: bold;
  }

  .dropdown {
    max-width: 100% !important;
  }

  .vs-table {
    .vs-table--thead {
      background-color: rgba(var(--vs-primary), 1);
      color: white;

      tr {
        background-color: rgba(var(--vs-primary), 1);
      }
    }
  }

  .custom-table {
    border: 1px solid rgba(var(--vs-primary), 1);
    border-collapse: collapse;

    thead {
      background-color: rgba(var(--vs-primary), 1);
      //tr {
      //  border: 1px solid rgba(var(--vs-primary), 1) !important;
      //}
    }

    th {
      color: white;
      text-align: center;
    }

    td {
      font-size: 16px;
      text-align: center;
    }

    .sign {
      font-size: 24px;
    }
  }
}

</style>
